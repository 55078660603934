import React , {useState, useEffect, useContext} from 'react';
import { useDispatch } from 'react-redux';
import JWTContext from 'contexts/JWTContext';
import useAuth from 'hooks/useAuth';
import { axiosClient } from 'utils/apiClient';
import serviceEndPoints from 'services/endPoints';

import Avatar from '@mui/material/Avatar';

import Logo from 'assets/images/logo/unisearch-logo.png';

const ClientLogoSection = () => {
    
    const dispatch = useDispatch();    
    const { user } = useContext(JWTContext);

    const logo = process.env.REACT_APP_SERVER_URL+serviceEndPoints.common.logo + "/"+user?.client?.id;
    //const logo = process.env.REACT_APP_SERVER_URL+serviceEndPoints.common.logo + "/"+3;
        
    return (
        <React.Fragment>
	     <Avatar
		 alt="LOGO"
		 //src={logo}
		 sx={{ width: '150px', height: '60px', background: 'transparent' }}
		 variant="square"
		 
	     >
		 <img src={logo} height="100%" width="100%" />
            </Avatar>		 
        </React.Fragment>
    );
};

export default ClientLogoSection;
