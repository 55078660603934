import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import serviceEndPoint from 'services/endPoints';
import { axiosClient } from 'utils/apiClient';

import NavGroup from './NavGroup';
import menuItem from 'menu-items';


import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined'; //Case registration
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'; //Data Entry
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined'; //Verification / Operations
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'; //Report
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; //User
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'; //Client
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'; // General Masters
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp'; // Configuration
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';

const icons = {
	DashboardIcon: HomeOutlinedIcon,
	CasesRegistrationIcon: CasesOutlinedIcon,
	DataEntryIcon: StorageOutlinedIcon,
	OperationsIcon: DomainVerificationOutlinedIcon,
	ReportsIcon: InsertChartOutlinedIcon,
	UserIcon: AccountCircleOutlinedIcon,
	ClientIcon: CorporateFareOutlinedIcon,
	CheckMasters: DoneAllIcon,
	GeneralMastersIcon: AccountTreeOutlinedIcon,
	SettingsApplicationsSharpIcon: SettingsApplicationsSharpIcon,
	InstitutionIcon : SchoolTwoToneIcon
};


const MenuList = () => {

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
        case 'group':
            return <NavGroup key={item.id} item={item} />;
        default:
            return (
                <Typography key={item.id} variant="h6" color="error" align="center">                                                     
                    Menu Items Error                                                                                                     
                </Typography>
            );
        }
    });
    
    return navItems;
    
};

export default MenuList;
