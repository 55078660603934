let authAPI = '/api/auth';
let clientPortalAPI = '/api/clientportal';
let userAPI = '/api/users';
export default {
    auth: {
        login: authAPI + '/login', //POST
        logout: authAPI + '/logout', //POST
        userfromtoken: authAPI + '/userfromtoken',
        loginterms: authAPI + '/loginterms',
    },
    users:{
	resetcurrentpassword : userAPI + '/user/resetcurrentpassword'
    },
    common: {
	logo: clientPortalAPI + '/logo',
    },
    dashboard: {
        clientdevices: clientPortalAPI + '/clientdevices',
	dashboardlivedata: clientPortalAPI + '/dashboard/livedata',
	dashboardlivedatachart: clientPortalAPI + '/dashboard/livedatachart',
	dashboardtrenddata: clientPortalAPI + '/dashboard/livetrend',
	dashboardchanneldata: clientPortalAPI + '/dashboard/channeldata',
	clientdevicedata : clientPortalAPI + '/clientdevicedata',
	channelheaders : clientPortalAPI + '/dashboard/channelheaders',
	clientdevicedatasearch : clientPortalAPI + '/dashboard/channeldatasearch',
	aluminiumpotline : clientPortalAPI + '/dashboard/aluminiumpotline',
	reportheadershiftwise: clientPortalAPI + '/dashboard/reportheadershiftwise',
	reportshiftwisesearch: clientPortalAPI + '/dashboard/reportshiftwisesearch',
    },
};

