import React, {useContext} from 'react';
import JWTContext from 'contexts/JWTContext';
import {
    Button,
    Chip,
    ClickAwayListener,
    Fade,
    Grid,
    Paper,
    Popper,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    ListItemSecondaryAction,
    Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import PerfectScrollbar from 'react-perfect-scrollbar';

import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';

import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';

import User1 from 'assets/images/users/avatar-1.jpg';
import User2 from 'assets/images/users/avatar-2.jpg';
import User3 from 'assets/images/users/avatar-3.jpg';
import User4 from 'assets/images/users/avatar-4.jpg';

const useStyles = makeStyles((theme) => ({
    grow: {
        flex: 1,
    },
    root: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '250px',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 0,
        borderRadius: '10px',
    },
    inline: {
        display: 'inline',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    subHeader: {
        backgroundColor: theme.palette.grey.A400,
        color: theme.palette.common.white,
        padding: '5px 15px',
    },
    subFooter: {
        backgroundColor: theme.palette.grey.A400,
        color: theme.palette.common.white,
        padding: 0,
    },
    iconButton: {
        padding: '5px',
    },
    showIcon: {
        transform: 'rotate(90deg)',
    },
    listSection: {
        backgroundColor: 'inherit',
        display: 'block',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    listAction: {
        top: '22px',
    },
    actionIcon: {
        fontSize: '0.75rem',
        marginRight: '4px',
        color: theme.palette.grey[400],
    },
    actionColor: {
        color: theme.palette.grey[400],
    },
    ScrollHeight: {
        height: '320px',
        overflowX: 'hidden',
    },
    p0: {
        padding: 0,
    },
    pT0: {
        paddingTop: 0,
    },
    menuIIcon: {
        fontSize: '1.5rem',
	color: '#000',
    },
    menuButton: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '35px',
        }
    },
}));

const ClientSection = () => {
    const classes = useStyles();
     const { user } = useContext(JWTContext);
    return (
        <React.Fragment>
            <Typography variant="subtitle1">{user?.client?.name}</Typography>
        </React.Fragment>
    );
};

export default ClientSection;
