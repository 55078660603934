import React from 'react';
import clsx from 'clsx';
import { useMediaQuery, useTheme, AppBar, Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { drawerWidth } from 'store/constant';
import Header from './Header';
import Sidebar from './Sidebar';
import { ErrorComponent, useAccessDenied } from 'utils/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        width: '100%',
        minHeight: '100vh',
        flexGrow: 1,
        /*padding: theme.spacing(3),*/
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },
    header: {
        zIndex: 1000,
        backgroundColor: '#fff',
        borderBottom: '4px solid #8a2387',
        borderImage: 'linear-gradient(90deg,#8a2387,#e94057,#f27121)',
        borderImageSlice: 1,
    },
}));

const MainLayout = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    React.useEffect(() => {
        setDrawerOpen(matchUpMd);
    }, [matchUpMd]);

    const loading = useSelector((state) => state.load);
    const { accessDenied } = useAccessDenied();
    const { denied, status, message } = accessDenied;

    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        dispatch({ type: 'ERROR', error: '' });
        dispatch({ type: 'SEARCH', error: '', search: '', place: '' });
    }, [location.pathname]) // This will ensure that when ever we change the url the ErrorSate Will be empty

    const LoadingAnimation = <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => { return theme.zIndex.drawer + 1 && theme.zIndex.modal + 1 } }}
        open={loading.loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>


    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar>
                    <Header drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
                </Toolbar>
            </AppBar>
            <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
            <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
                <div className={classes.toolbar} />
                <div className={classes.main}>
                    {denied && (<>
                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            {message} — <strong>Status Code : {status}</strong>
                        </Alert>
                    </>
                    )}
                    {!denied && (<>
				     {/*  {LoadingAnimation} */}
                        <ErrorComponent />
                        <Outlet />
                    </>
                    )}
                </div>
            </main>
        </Box>
    );
};

export default MainLayout;
