import React from 'react';
import { Box, Grid, IconButton, Hidden } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import ProfileSection from './ProfileSection';
import ClientSection from './ClientSection';
import ClientLogoSection from './ClientLogoSection';
//import NotificationSection from './NotificationSection';

import Logo from 'assets/images/logo/unisearch-logo.png';
import { drawerWidth } from 'store/constant';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
	color: '#000',
    },
}));

const Header = (props) => {
    const { drawerToggle } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box width={drawerWidth}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Hidden mdDown>
                        <Grid item>
                            <Box mt={0.5}>
                                <img src={Logo} alt="Logo" />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                            size="large">
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
	    <ClientSection />
            <div className={classes.grow} />
	    <ClientLogoSection />
	    <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
