import React, { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'component/Auth/AuthGuard';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard/Default')));
const DashboardTrend = Loadable(lazy(() => import('views/Dashboard/Trend')));
const DashboardDateWiseReport = Loadable(lazy(() => import('views/Dashboard/Report')));
const DashboardShiftWiseReport = Loadable(lazy(() => import('views/Dashboard/Report/ShiftWiseReport')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
        },
	 {
            path: '/dashboard/trend',
            element: <DashboardTrend />,
         },
	 {
            path: '/dashboard/report',
            element: <DashboardDateWiseReport />,
         },
	 {
            path: '/dashboard/shiftwisereport',
            element: <DashboardShiftWiseReport />,
         },
    ],
};

export default MainRoutes;
