/**
*   @File        : apiClient.js
*   @module      : Services
*
*   @purpose     : Common axios services file.
*
*   @author      : Sanjit Malik (sanjit@likemindtech.com)
*
*   @created     :   06-Feb-2023
*
* *********************************************************************
*        Copyright (C) Nilgiris Likemind Technologies, 2022-2023
*                       All Rights Reserved.
*    The copying, invocation or execution of this product constitutes
*    full knowledge of Likemind Technologies rights and agreement
*    to its licensing policies.
* *********************************************************************
*/

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertPopup } from 'component/Muihookform/Fields';
import { store } from 'index.js'

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});


axiosClient.interceptors.request.use(
    (config) => {
        config.timeout = 60000;
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
            config.headers["Authorization"] = 'Bearer ' + serviceToken;  // for Spring Boot back-end
        }
        const data = store.getState();
        if (config.url.includes('search') && data.error.place !== 'QAS') { // for normal search
            store.dispatch({ type: 'SEARCH', place: 'S', search: true, url: config.url })
        }
        if (data.error.place !== 'QAS') {
            store.dispatch({ type: 'LOAD', loading: true });
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    function (response) {
        return new Promise(resolve => {
            setTimeout(() => {
                if (response.status === 200 || response.status === 201) {
                    store.dispatch({ type: 'ERROR', error: '', place: '' });
                    store.dispatch({ type: 'LOAD', loading: false });
                }
                resolve(response);
            }, 300); // resolve after 300ms and this will not affect the performance
        });
    },
    function (error) {
        let res = error.response;
        if (res?.status === 401) {
            window.location.href = '/login';
        }
        if (error?.code === 'ECONNABORTED') {
            store.dispatch({ type: 'ERROR', error: res, place: 'TIME' }) // Here TIME  means connnection timeout 
            store.dispatch({ type: 'LOAD', loading: false });
        } else {
            const data = store.getState();
            if (data.search.place === 'S' && data.search.url === error.config.url) {
                if (data.search.url.includes('search') && res?.status === 403) {
                    store.dispatch({ type: 'SEARCH', error: res, place: 'S', search: true, status: res.status });
                    store.dispatch({ type: 'LOAD', loading: false });
                } else if (data.search.url.includes('search') && res.status === 503) {
                    store.dispatch({ type: 'ERROR', error: res, place: 'GEN' });
                    store.dispatch({ type: 'LOAD', loading: false });
                } else if (data.search.url.includes('search') && res.status === 500) {
                    store.dispatch({ type: 'ERROR', error: res, place: 'GEN' });
                    store.dispatch({ type: 'LOAD', loading: false });
                }
            } else if (!error?.response?.data?.errorMessages && data.error.place !== 'QAS') {
                store.dispatch({ type: 'ERROR', error: res, place: 'GEN' }) // Here GEN means general
                store.dispatch({ type: 'LOAD', loading: false });
            } else if (data.error.place === 'QAS' && (res.status === 503 || res.status === 500)) {
                store.dispatch({ type: 'ERROR', error: res, place: 'GEN' }) // Here GEN means general
            } else {
                store.dispatch({ type: 'LOAD', loading: false });
            }
            return Promise.reject(error);
        }
    }
);

function ErrorComponent() {
    const errorMessage = useSelector((state) => state.error);
    const [error, setError] = useState(false);
    const [customErrorMessage, setCustomErrorMessage] = useState({ status: '', message: '' });
    useEffect(() => {
        if (errorMessage?.error !== '' && errorMessage?.place === 'GEN') {
            if (errorMessage?.error?.status === 400) {
                if (errorMessage?.error.data.error) {
                    setError(true); // If you place setError out side means it will also show for input validations too
                    setCustomErrorMessage({ status: errorMessage.error.data.status, message: errorMessage?.error.data.error });
                }
            } else if (errorMessage?.error?.status === 500) {
                setError(true);
                setCustomErrorMessage({ status: 500, message: 'Seems like there is an issue with the server.' });
            } else if (errorMessage?.error?.status === 503) {
                setError(true);
                setCustomErrorMessage({ status: 503, message: 'Seems like server is temporarly down' });
            } else if (errorMessage?.error?.status === 404) {
                setError(true);
                if (errorMessage?.error?.data?.error) {
                    setCustomErrorMessage({ status: 404, message: errorMessage?.error.data.error })
                } else {
                    setCustomErrorMessage({ status: 404, message: 'Sorry! Seems like this functionality is not found' })
                }
            } else if (errorMessage?.error?.status === 403) {
                if (errorMessage.place === 'GEN') {
                    setError(true);
                    setCustomErrorMessage({ status: 403, message: "Forbidden to Access" });
                }
            }
        } else if (errorMessage?.error !== '' && errorMessage?.place === 'TIME') {
            setError(true);
            setCustomErrorMessage({ message: "Connection Time out try to reload the page or try again" });
        }
    }, [errorMessage]);
    const dispatch = useDispatch();
    const handleAction = () => {
        dispatch({ type: 'ERROR', error: '' });
    }
    const message = customErrorMessage?.message;
    // const message = customErrorMessage?.status !== undefined ? customErrorMessage?.message + ' : ' + customErrorMessage?.status + ' status code' : customErrorMessage?.message;

    return (<>
        <AlertPopup
            success={error}
            method={setError}
            message={message}
            variant='error'
            seconds={10000}
            customAction={handleAction}
        />
    </>)
}



function useAccessDenied() {
    const commonErrors = useSelector((state) => state.search);
    const [accessDenied, setAccessDenied] = useState({ denied: false, status: '', message: '' });
    useEffect(() => {
        if (commonErrors?.error !== '') {
            if (commonErrors?.error?.status === 403) {
                if (commonErrors.place === 'S' && commonErrors.search === true // S for Search if search is disabled means there is no use of showing the pages
                ) {
                    setAccessDenied({ denied: true, status: 403, message: "Sorry, But you don't have access to view this page. Try with proper credentials" });
                }
            }
        } else {
            setAccessDenied({ denied: false, status: '', message: "" });
        } // Ensure to show the normal page
    }, [commonErrors]);
    return { accessDenied };
}

export { axiosClient, ErrorComponent, useAccessDenied };
